@media (min-width: 768px) {

    /* Navbar */

    .navbar-expanded .navbar-nav li a {
        text-shadow: 2px 2px #999;
    }

    .navbar.navbar-inverse.navbar-fixed-top.navbar-expanded {
        background-color: transparent;
    }

    .navbar-inverse .navbar-collapse {
        background-color: transparent;
    }

    .navbar.navbar-inverse.navbar-fixed-top {
        background-color: #222222;
    }

    /* Heading */

    header .intro-content {
        color: #444;
        top: 25%;
    }

    /* Text content */

    .process-text {
        width: 50%;
        display: inline-block;
    }

    .text-center-responsive {
        text-align: center;
    }

}