/* Nav */

.navbar.navbar-inverse.navbar-fixed-top {
    background-color: #222222;
}

.navbar.navbar-inverse.navbar-fixed-top.navbar-expanded {
    background-color: transparent;
}

.navbar-inverse .navbar-collapse {
    background-color: #222222;
}


/* Header */

header .intro-content {
    color: white;
    text-shadow: 2px 1px #999;
}

header .brand-name-subtext {
    text-shadow: 2px 1px #000;
}


/* Show or hide logo based on scroll */

.logo-expanded {
    display: block !important;
}

.logo-bw {
    display: none !important;
}

.navbar-expanded .logo-expanded {
    display: none !important;
}

.navbar-expanded .logo-bw {
    display: block !important;
}

/* Our Team */

.team-photo {
    display: inline-block;
}

/* Ray Flex */
.img-ray-flex {
}

.text-ray-flex img {
    height: 19px;
}

.img-ray-flex, .text-ray-flex {
    display: none;
}

.ray-flex:hover .img-ray-not-flex {
    display: none;
}

.ray-flex:hover .img-ray-flex, .ray-flex:hover .text-ray-flex {
    display: inline-block;
}

/* Portfolio */

#portfoliolist .portfolio .portfolio-wrapper {
    cursor: auto;
}

.text-category a {
    color: white;
}

/* Footer */

#google-map-wrapper {
    position: relative;
}

#googlemaps {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; /* Set z-index to 0 as it will be on a layer below the contact form */
}

#google-map-footer {
    position: relative;
    z-index: 1; /* The z-index should be higher than Google Maps */
}

#google-map-footer .contact-details {
    background:rgba(0,0,0,0.6);
    padding: 20px;
    color: white;
}

/* Contact */

.help-block.text-danger ul {
    list-style-type: none;
    font-style: italic;
    color: #178ACC;
    padding-left: 0;
}